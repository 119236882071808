define("discourse/plugins/discourse-moderator-attention/discourse/initializers/extend-for-moderator-attention", ["exports", "handlebars", "discourse/lib/plugin-api", "discourse/raw-views/topic-status", "discourse-common/helpers/fa-icon", "discourse-common/utils/decorators", "I18n"], function (_exports, _handlebars, _pluginApi, _topicStatus, _faIcon, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function initializeModeratorAttention(api) {
    api.decorateWidget("post:classNames", dec => {
      const post = dec.getModel();
      if (post.get("requiresReview")) {
        return ["requires-review"];
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-moderator-attention",
    initialize(container) {
      var _dec, _obj, _dec2, _obj2, _dec3, _obj3;
      const currentUser = container.lookup("service:current-user");
      if (!currentUser || !currentUser.get("moderator")) {
        return;
      }
      const Post = container.factoryFor("model:post").class;
      Post.reopen((_dec = (0, _decorators.default)(), (_obj = {
        requiresReview() {
          const unreviewed = this.get("topic.unreviewed_post_numbers");
          if (!unreviewed) {
            return;
          }
          return unreviewed.indexOf(this.get("post_number")) !== -1;
        }
      }, (_applyDecoratedDescriptor(_obj, "requiresReview", [_dec], Object.getOwnPropertyDescriptor(_obj, "requiresReview"), _obj)), _obj)));
      const TopicController = container.factoryFor("controller:topic").class;
      TopicController.reopen({
        readPosts(topicId, postNumbers) {
          const topic = this.get("model.postStream.topic");
          if (topic && topic.get("id") === topicId) {
            const unreviewed = topic.get("unreviewed_post_numbers");
            if (unreviewed) {
              const initial = unreviewed.length;
              unreviewed.removeObjects(postNumbers);
              topic.set("requires_review", unreviewed.length > 0);
              if (unreviewed.length === 0 && initial === 1) {
                topic.set("fully_reviewed", true);
              }
            }
          }
          this._super(topicId, postNumbers);
        }
      });

      // used in topic (TODO centralize this)
      const TopicStatusComponent = container.factoryFor("component:topic-status").class;
      const icon = (0, _faIcon.iconHTML)("asterisk");
      TopicStatusComponent.reopen((_dec2 = (0, _decorators.observes)("topic.unreviewed_post_numbers.[]"), (_obj2 = {
        unreviewedChanged() {
          const unreviewed = this.get("topic.unreviewed_post_numbers");
          if (!unreviewed) {
            return;
          }
          if (unreviewed.length === 0) {
            this.rerender();
          } else {
            // ninja in url so it does not flash on rerender
            const first = this.element.querySelector(".unreviewed");
            if (first) {
              first.href = this.get("topic.url") + "/" + unreviewed[0];
            }
          }
        },
        renderString(buffer) {
          const posts = this.get("topic.unreviewed_post_numbers");
          const fullyReviewed = this.get("topic.fully_reviewed");
          if (fullyReviewed || posts && posts.length > 0) {
            const title = _handlebars.default.Utils.escapeExpression(_I18n.default.t("mod_attention.requires_review"));
            const url = this.get("topic.url") + "/" + posts[0];
            let reviewedClass = fullyReviewed ? "reviewed" : "unreviewed";
            buffer.push(`<a href='${url}' title='${title}' class='topic-status ${reviewedClass}'>${icon}</a>`);
          }
          this._super(buffer);
        }
      }, (_applyDecoratedDescriptor(_obj2, "unreviewedChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "unreviewedChanged"), _obj2)), _obj2)));
      _topicStatus.default.reopen((_dec3 = (0, _decorators.default)("topic.requires_review", "topic.url"), (_obj3 = {
        statuses(requiresReview, topicUrl, fullyReviewed) {
          const results = this._super();
          if (requiresReview || fullyReviewed) {
            results.push({
              openTag: "a href",
              closeTag: "a",
              title: _I18n.default.t("mod_attention.requires_review"),
              icon: "asterisk",
              href: `${topicUrl}/${requiresReview}`
            });
          }
          return results;
        }
      }, (_applyDecoratedDescriptor(_obj3, "statuses", [_dec3], Object.getOwnPropertyDescriptor(_obj3, "statuses"), _obj3)), _obj3)));
      (0, _pluginApi.withPluginApi)("0.1", api => initializeModeratorAttention(api));
    }
  };
});